import { type ClassValue, clsx } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { auth } from "~/firebase.client";
import { signInWithCustomToken } from "firebase/auth";
import { useSubmit } from "@remix-run/react";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useOutsideClick(ref: any, callback: () => void) {
  useEffect(() => {
    // Function to detect click event
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export type GeolocationResponse = {
  ip: string;
  city: string;
  countryName: string;
  countryCode: string;
  regionName: string;
  timezone: string;
  lat: number;
  lon: number;
};

type IpApiResponse = {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  query: string;
  region: string;
  regionName: string;
  status: string;
  timezone: string;
  zip: string;
};

type IpapiCoResponse = {
  ip: string;
  network: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
};

function normalizeIpApiResponse(data: IpApiResponse): GeolocationResponse {
  return {
    ip: data.query,
    city: data.city,
    countryName: data.country,
    countryCode: data.countryCode,
    regionName: data.regionName,
    timezone: data.timezone,
    lat: data.lat,
    lon: data.lon,
  };
}

function normalizeIpapiCoResponse(data: IpapiCoResponse): GeolocationResponse {
  return {
    ip: data.ip,
    city: data.city,
    countryName: data.country_name,
    countryCode: data.country_code,
    regionName: data.region,
    timezone: data.timezone,
    lat: data.latitude,
    lon: data.longitude,
  };
}

export async function getGeolocation(): Promise<
  GeolocationResponse | undefined
> {
  try {
    // Try ip-api.com first
    const ipApiResponse = await fetch(
      "https://pro.ip-api.com/json/?key=I9Cd2AbL64QbZsN"
    );

    if (ipApiResponse.ok) {
      const data = await ipApiResponse.json();
      return normalizeIpApiResponse(data);
    }

    // If first API fails, try ipapi.co
    const ipapiCoResponse = await fetch("https://ipapi.co/json/");

    if (ipapiCoResponse.ok) {
      const data = await ipapiCoResponse.json();
      return normalizeIpapiCoResponse(data);
    }

    return undefined;
  } catch (error) {
    console.error("Failed to fetch geolocation data:", error);
    return undefined;
  }
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value); // Update debounced value after delay
    }, delay);

    // Cleanup the timeout if value changes before the delay completes
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export function useSignInWithCustomToken(token?: string | null) {

  const submit = useSubmit();
  useEffect(() => {
  if (auth && token) {

    signInWithCustomToken(auth, token).then((userCredential) => {
      userCredential.user.getIdToken().then((idToken) => {
        submit(
          {
            idToken,
          },
          { method: "post", action: "/auth/login" }
        );
      });
    });
  }
}, [auth, token]);
}

export type FieldErrors = Record<string, string[]>;

export function validateForm<T extends z.ZodTypeAny>(
  formData: FormData,
  schema: T
):
  | {
      data: z.infer<T>;
      errors: null;
    }
  | {
      data: null;
      errors: FieldErrors;
    } {
  const result = schema.safeParse(Object.fromEntries(formData));

  if (result.success) {
    return { data: result.data, errors: null };
  } else {
    const formattedErrors: FieldErrors = {};
    Object.entries(result.error.formErrors.fieldErrors).forEach(
      ([key, value]) => {
        formattedErrors[key] = value || [];
      }
    );
    return {
      data: null,
      errors: formattedErrors,
    };
  }
}
